import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    query {
      allContentfulServicesListContent(sort: { fields: order }) {
        nodes {
          node_locale
          description {
            description
          }
          title
          order
        }
      }
      allContentfulServicesImages {
        nodes {
          node_locale
          coverImageSmall {
            gatsbyImageData
            file {
              url
            }
            title
          }
          coverImage {
            gatsbyImageData
            file {
              url
            }
            title
          }
        }
      }
    }
  `);
};

export default query;
