import * as React from "react";
import Header from "../../Components/Header/Header";
import Services from "../../Components/Services/Services";
import ContactFooter from "../../Components/ContactFooter/ContactFooter";
import Partnership from "../../Components/Partnership/Partnership";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";

const ServiziPage = () => {
  return (
    <>
      <Seo locale="en-US" page="services" />
      <Header lang="en-US" />
      <HamburgerMenu />
      <Services lang="en-US" />
      <ContactFooter
        isDarkTheme={false}
        isBorderBottom={true}
        ctaInverted={false}
        additionalText="SERVIZI"
        lang="en-US"
      />
      <Partnership lang="en-US" />
      <Footer lang="en-US" />
    </>
  );
};

export default ServiziPage;
