import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./ContactFooter.scss";
import query from "../ContactFooter/query";
import CallToAction from "../CallToAction/CallToAction";
import { filterDataByLanguage } from "../../Utils/Language";
import PropTypes from "prop-types";

const ContactFooter = (props) => {
  // Manage Theme dark or bright
  const isDarkTheme = props.isDarkTheme;
  const isBorderBottom = props.isBorderBottom;
  let theme = "bright";
  if (isDarkTheme === true) {
    theme = "dark";
  }

  let borderBottom = "";
  if (isBorderBottom === true) {
    borderBottom = "contact-footer-bottom-border";
  }

  const data = query();
  const contactFooterData = filterDataByLanguage(
    props.lang,
    data.allContentfulContactFooterContent
  );

  return (
    <div className={"contact-footer-container-" + theme}>
      <Container className="pb-5">
        <Row className={borderBottom}>
          <Col className="col-lg-6 col-1 contact-footer-additional-text">
            {props.additionalText !== undefined ? props.additionalText : ""}
          </Col>
          <Col
            className={
              "col-lg-6 col-12 text-lg-start contact-footer-title contact-footer-title-" +
              theme
            }
          >
            <div className="pb-5">{contactFooterData.title.title}</div>
            <div className={"link-arrow-animation link-" + theme}>
              <div className="d-inline-block col-lg-3 col-4 align-middle">
                <CallToAction
                  to={contactFooterData.contactFooterLink}
                  label={contactFooterData.contactFooterLinkText}
                  inverted={
                    props.ctaInverted !== undefined ? props.ctaInverted : true
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ContactFooter.propTypes = {
  lang: PropTypes.string.isRequired,
  isDarkTheme: PropTypes.bool,
  isBorderBottom: PropTypes.bool,
  additionalText: PropTypes.string,
  ctaInverted: PropTypes.bool,
};

export default ContactFooter;
