import * as React from "react";
import "./Item.scss";
import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import PropTypes from "prop-types";
gsap.registerPlugin(ScrollTrigger);

/**
 * Service List Item
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Item = (props) => {
  useEffect(() => {
    const tl = gsap.timeline({
      repeat: 1, // number of repeats (-1 for infinite)
      repeatDelay: 1, // seconds between repeats
      repeatRefresh: true, // invalidates on each repeat
      smoothChildTiming: true,
      autoRemoveChildren: true,
    });

    const items = document.querySelectorAll(".services-container .items .item");

    items.forEach((item) => {
      tl.to(item, {
        scrollTrigger: {
          trigger: item,
          start: "top center",
          end: "20% center",
          scrub: true,
          markers: false,
        },
        duration: 4,
        immediateRender: false,
      });

      tl.to(item, {
        scrollTrigger: {
          trigger: item,
          start: "top center",
          end: "bottom center",
          scrub: true,
          markers: false,
          toggleClass: "hovered",
        },
        duration: 4,
        immediateRender: false,
      });
    });
  }, []);

  return (
    <div className="items-wrapper">
      <div className="items">
        {props.serviceData.map((el, i) => (
          <div id={i} className="item row" key={i}>
            <div key={i} className="col-lg-6 col-12 services-list-item">
              <p>{el.title}</p>
            </div>
            <div className="col-lg-6 col-12 service-list-text">
              <p>{el.description.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="items-mobile" id="items-mobile-id">
        <div className="accordion accordion-flush">
          {props.serviceData.map((el, i) => (
            <div id={i} key={i} className="accordion-item ">
              <h2
                className="accordion-header "
                id={"panelsStayOpen-heading" + i}
              >
                <button
                  className="accordion-button collapsed"
                  type="button"
                  // data-bs-toggle="collapse"
                  // data-bs-target={"#panelsStayOpen-collapse" + i}
                  aria-expanded="true"
                  aria-controls={"panelsStayOpen-collapse" + i}
                >
                  {el.title}
                </button>
              </h2>
              <div
                id={"panelsStayOpen-collapse" + i}
                // data-bs-parent="#items-mobile-id"
                className="accordion-collapse collapse show"
                aria-labelledby={"panelsStayOpen-heading" + i}
              >
                <div className="accordion-body item-description">
                  {el.description.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Item.propTypes = {
  serviceData: PropTypes.object,
};

export default Item;
