import * as React from "react";
import { Container } from "react-bootstrap";
import "./Services.scss";
import Item from "./Item/Item";
import query from "../Services/query";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import {
  filterDataByLanguage,
  filterListDataByLanguage,
} from "../../Utils/Language";
import PropTypes from "prop-types";

/**
 * Fade-in effect on cover image
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function FadeInSection(props) {
  const [isVisible, setVisible] = React.useState(true);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    // return () => observer.unobserve(domRef.current);
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

/**
 * Services List
 * @returns {JSX.Element}
 * @constructor
 */
const Services = (props) => {
  const data = query();
  const images = filterDataByLanguage(
    props.lang,
    data.allContentfulServicesImages
  );
  const contentData = filterListDataByLanguage(
    props.lang,
    data.allContentfulServicesListContent
  );

  return (
    <div className="services-container-page">
      <Container className="services-container">
        <div className="">
          <div className="services-title-mobile" />
          {contentData && <Item serviceData={contentData} />}
        </div>
      </Container>
      <div>
        <FadeInSection>
          <div className="image-container">
            <div>
              {images && (
                <ResponsiveImage
                  desktopImage={images.coverImage}
                  mobileImage={images.coverImageSmall}
                  alt={images.coverImageSmall.title}
                  className="image-cover"
                />
              )}
            </div>
          </div>
        </FadeInSection>
      </div>
    </div>
  );
};

function ResponsiveImage(props) {
  const images = withArtDirection(getImage(props.desktopImage), [
    {
      media: "(max-width: 960px)",
      image: getImage(props.mobileImage),
    },
  ]);
  return (
    <GatsbyImage
      alt={props.alt}
      image={images}
      imgClassName={props.className}
    />
  );
}

ResponsiveImage.propTypes = {
  desktopImage: PropTypes.string.isRequired,
  mobileImage: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string.isRequired,
};

FadeInSection.propTypes = {
  children: PropTypes.string,
};

Services.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Services;
