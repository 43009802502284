import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    query {
      allContentfulContactFooterContent {
        nodes {
          node_locale
          title {
            title
          }
          contactFooterLinkText
          contactFooterLink
        }
      }
    }
  `);
};

export default query;
